<!-- 首页右下半部分 -->
<template>
  <div
    style="
      text-align: center;
      background-color: #f1f1f3;
      height: 100%;
      padding: 0px;
      margin: 0px;
    "
  >
    <h1 style="font-size: 50px">欢迎您！{{ user.name }}</h1>
    <el-descriptions title="个人中心" :column="2" size="40" border>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-s-custom"></i>
          账号
        </template>
        {{ user.no }}
      </el-descriptions-item>

      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-s-shop"></i>
          店铺名称
          {{ ($store.getters.getMenu.role = 1 ? user.s_name : "") }}
        </template>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          角色
        </template>
        <el-tag type="success" disable-transitions>{{
          user.role == 0 ? "管理员" : "商家"
        }}</el-tag>
      </el-descriptions-item>
    </el-descriptions>

    <DateUtils></DateUtils>
  </div>
</template>

<script>
import DateUtils from "./DateUtils";
export default {
  name: "Home",
  components: { DateUtils },
  data() {
    return {
      user: {},
    };
  },
  computed: {},
  methods: {
    init() {
      this.user = this.$store.state.menu;
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.el-descriptions {
  width: 90%;

  margin: 0 auto;
  text-align: center;
}
</style>